import styled from "styled-components"
import { COLORS } from "../../constants/colors";

export const InputContainer = styled.div`
    gap: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
`;

export const Input = styled.input`
    font-size: 14px;
    background-color: #182037;
    border-radius: 8px;
    margin: 0px 16px;
    width: 83vw;
    padding: 0px 16px 0px 16px;
    border: 1px solid ${COLORS.kindness100};
    height: 44px;
    color: ${COLORS.kindness100};
    ${props => `border: 1px solid ${!props.hasError ? COLORS.kindness100 : 'red'};}`};

    color: ${COLORS.kindness100};
    font-weight: 400;

    &:focus {
        border: 1px solid ${COLORS.kindness100};
        outline: none;
    } 

    &::placeholder {
        color: ${COLORS.kindness100};
    }
`;

export const ErrorMessage = styled.span`
    color: red;
    padding-left: 17px;
    font-size: 14px;
`;