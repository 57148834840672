import styled from 'styled-components';

export const Footer = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-around;

    button {
        margin-bottom: 18px;
    }
`;