import React from 'react';

import * as S from './styles';

function Footer({ children }) {

    return (
        <S.Footer>
            {children}
        </S.Footer>
    );
}

export default Footer;
