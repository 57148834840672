import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { useOnboarding } from '../../hooks/onboarding';
import Button from '../../components/Button/Button';
import * as S from './styles';

function Home() {
    const navigate = useNavigate();
    const { setStarted } = useOnboarding();

    const onInit = () => {
        localStorage.setItem('initial', true);
        setStarted(true);
        navigate("/onboarding");
    }
    return (
        <S.Container>
            <Footer>
                <Button label={"Confirmar"} onClick={() => navigate("/confirmsPresence")} disabled={false} kind={'secondary'} />
                <Button label={"Criar conta"} onClick={() => onInit()} disabled={false} kind={'primary'} />
            </Footer>
        </S.Container>
    );
}

export default Home;