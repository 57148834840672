import styled from "styled-components"
import { COLORS } from "../../constants/colors";

export const InputContainer = styled.div`
    gap: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    
    margin: 0px 16px;

    #react-select-5-placeholder{
        color: ${COLORS.kindness100};
    }

    .react-select__menu{
        background-color: #182037;
        color: ${COLORS.kindness100};
        
    }
    .react-select__control{
        height: 44px;
        border-radius: 8px;
        border: 1px solid ${COLORS.kindness100};
        background-color: #182037;
        color: ${COLORS.kindness100};
        font-size: 14px;
    }

    .react-select__value-container,
    .react-select__indicators, 
    .react-select__single-value,
    .react-select__option,
    .react-select__placeholder {
        background-color: #182037;
        color: ${COLORS.kindness100};
        font-size: 14px;
    }

    .react-select__indicators {
        margin-right: 6px;
    }

`;

export const ErrorMessage = styled.span`
    color: red;
    font-size: 14px;
`;