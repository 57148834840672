import React, { useCallback, useState } from 'react';
import { object, string, ValidationError } from 'yup';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input/Input';
import Footer from '../../components/Footer/Footer';
import Button from '../../components/Button/Button';

import * as S from './styles';
import { cpfValidation } from '../../services/cpfValidation';

function getValidationErrors(err) {
    const validationErrors = {};

    err.inner.forEach(error => {
        validationErrors[error.path] = error.message;
    });

    return validationErrors;
}

function cpfMask(v) {
    v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
}

const ConfirmsPresence = () => {
    const [document, setDocument] = useState("");
    const [errors, setErrors] = useState();
    const [showInvalidCPF, setShowInvalidCPF] = useState(false);
    const navigate = useNavigate();
    let userSchema = object().shape({
        document: string().min(11, 'Insira um CPF válido').required("CPF obrigatório"),
    });

    const handleSubmit = useCallback(async () => {
        try {
            const formData = {
                document: document.replace(/[^0-9]/g, ""),
            };

            await userSchema.validate(formData, { abortEarly: false });
            const response = await cpfValidation(document.replace(/[^0-9]/g, ""))
            const code = response.code;
            var errorMessage = '';

            if (code !== '200') {
                switch (code) {
                    case '404':
                        errorMessage = 'CPF inválido, nenhum convite disponível.';
                        break;
                    case '500':
                        errorMessage = 'Cadastro indisponível, retorne ao menu principal e realize seu cadastro.';
                        break;
                    case '422':
                        errorMessage = 'Sua presença já foi confirmada anteriormente.';
                        break;
                    default:
                        errorMessage = 'Ocorreu um erro inesperado, tente novamente ou mais tarde.'
                        break;
                }

                setErrors({
                    document: errorMessage
                })
                setShowInvalidCPF(true);
                return;
            } else {
                setShowInvalidCPF(false);
                navigate('/sucessConfirmed');
            }

        } catch (err) {
            if (err instanceof ValidationError) {
                setErrors(getValidationErrors(err));
                return;
            }
        }

    }, [document, navigate, userSchema]);

    const onChangeCPF = useCallback((value) => {
        const mask = cpfMask(value);
        setDocument(mask)
    }, []);

    return (
        <S.Container>
            <S.TitleContainer>
                <S.Title>Por favor, insira seu CPF:</S.Title>
            </S.TitleContainer>

            <S.FormContainer>
                <Input placeholder="CPF" type="text" name="document" onChange={(e) => onChangeCPF(e.target.value)} errorMessage={errors?.document} value={document} maxLength={14} />
            </S.FormContainer>
            {showInvalidCPF && <S.ContainerAlertCPF>
                <S.Alert>***Lembre-se: Para conseguir confirmar sua presença é necessário utilizar o mesmo CPF que você enviou para um dos organizadores previamente.</S.Alert>
                <S.Alert>Qualquer problema, contate um dos organizadores e tente novamente mais tarde.</S.Alert>
            </S.ContainerAlertCPF>}

            <Footer>
                <Button label={"Continuar"} onClick={() => handleSubmit()} disabled={false} kind={'primary'} />
            </Footer>
        </S.Container>
    );
}

export default ConfirmsPresence;