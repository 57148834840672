import styled from 'styled-components';

import { COLORS } from '../../constants/colors';

export const Container = styled.div`
    background-image: url("home.svg");
    height: 125vh;
    @media (max-width:375px)  { height: 147vh; }
    background-size: 100% !important;
    background-repeat: no-repeat;
`;

export const Title = styled.label`
    font-size: 16px;
    font-weight: 400;
    color: ${COLORS.securityNeutral100};
`;

export const HeaderContainer = styled.div`
    align-items: center;
    justify-content: center;

    img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
    }
`;

export const LogoContainer = styled.div`
margin-left: 22px;
    img {
        width: 80%;
        max-width: 400px;
        height: auto;
    }
`;


export const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-left: 20px;
    flex-direction: column;
    align-items: center;
`;

export const ContainerDescription = styled.div`
    display: flex;
    justify-content: center;
`;
