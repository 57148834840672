import styled, { css } from "styled-components"
import { COLORS } from "../../constants/colors";
import { match } from "../../utils/match";

export const Button = styled.button`
    background-color: ${COLORS.harmony500};
    /* padding: 16px 68px; */
    min-width: 168px;
    font-size: 16px;
    color: ${COLORS.kindness100};
    border-radius: 8px;

    ${({ kind }) =>
        match(kind, {
            "primary": () => css`
                background-color: ${COLORS.pink};
                color: ${COLORS.kindness100};
            `,
            "secondary": () => css`
                background-color: ${COLORS.securityNeutral600};
                border: 1px ${COLORS.pink} solid;
                color: ${COLORS.pink};
            `,
        })}

    &:disabled {
        background-color: ${COLORS.securityNeutral200};
    }
`;