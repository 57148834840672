import React from 'react';
import { createContext, useContext, useState, useCallback } from "react";

import api from '../services/api'

const OnboardingContext = createContext({});

const OnboardingProvider = ({ children }) => {

    const [data, setData] = useState({});
    const [started, setStarted] = useState(false);

    const setFace = useCallback(async (base64, jwtBase64) => {
        try {
            const dataOnboarding = {
                ...data,
                SelfieBase64: base64,
                SelfieBase64JWTUnico: jwtBase64
            }
            setData(dataOnboarding)

            console.log(dataOnboarding, 'dataOnboarding')
            await api.post(`/Onboarding?onboarding=partial`, dataOnboarding);
        } catch {
            console.log("Erro");
        }
    }, [data]);

    return (
        <OnboardingContext.Provider
            value={{ user: data, setData, started, setStarted, setFace }}
        >
            {children}
        </OnboardingContext.Provider>
    );
}

function useOnboarding() {
    const context = useContext(OnboardingContext);

    return context;
}

export { OnboardingProvider, useOnboarding };