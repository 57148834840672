import React from 'react';
import Select from 'react-select';
import * as S from './styles';

function InputSelect({ placeholder, type, name, onChange, errorMessage, value, options }) {

    return (
        <S.InputContainer hasError={errorMessage !== undefined}>
            <Select
                value={value}
                onChange={onChange}
                options={options}
                placeholder={placeholder}
                classNamePrefix="react-select"
            />
            {/* <S.Select
                type={type}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                hasError={errorMessage !== undefined}
                value={value}
            >
                <S.Option value="0">
                    Homem
                </S.Option>
            </S.Select> */}
            {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
        </S.InputContainer>
    );
}

export default InputSelect;