import styled from 'styled-components';

import { COLORS } from '../../constants/colors';

export const Container = styled.div`
    height: 100vh;
    background-color: ${COLORS.securityNeutral600};
`;

export const TitleContainer = styled.div`
    padding: 32px 16px;
`;

export const Title = styled.label`
    color: ${COLORS.harmony100};
`;

export const FormContainer = styled.form`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const ContainerAlertCPF = styled.div`
    text-align: start;
    display: flex;
    flex-direction: column;
    padding: 0px 18px;
`;

export const Alert = styled.p`
    color: red;
    font-size: 12px;
`;