import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import AppProvider from "./hooks/index";

import Home from "./pages/home/Home";
import Onboarding from "./pages/onboarding/Onboarding";
import FaceCheck from './pages/facecheck/FaceCheck';
import Success from './pages/success/Success';
import SuccessConfirmed from './pages/success-confirmed/SuccessConfirmed';
import ConfirmsPresence from './pages/confirms-presence/ConfirmsPresence';

export default function App() {
  return (
    <AppProvider>
      <Router>
        <div>
          <Routes>
            <Route element={<Home />} path="/" exact />
            <Route element={<Onboarding />} path="/onboarding" />
            <Route element={<FaceCheck />} path="/faceCheck" />
            <Route element={<Success />} path="/success" />
            <Route element={<SuccessConfirmed />} path="/sucessconfirmed" />
            <Route element={<ConfirmsPresence />} path="/confirmspresence" />
          </Routes>
        </div>
      </Router>
    </AppProvider>

  );
}