import styled from 'styled-components';

import { COLORS } from '../../constants/colors';

export const Container = styled.div`
    background-image: url("recognitionsuccess.svg");
    background-repeat: no-repeat;
    height: 120vh;
    @media (max-width:375px)  { height: 128vh; }
    background-size: 100% !important;
`;

export const MessageContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: center;
height: 100vh;
`;

export const Title = styled.label`
    font-size: 22px;
    color: ${COLORS.securityNeutral600};
    font-weight: 600;
`;