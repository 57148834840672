import api from './api';

export const cpfTicketValidation = async (cpf) => {
   
    const response = await api.get(`/ticket/188378ff-9892-45f1-bb00-647db24a64cf/ticket-verify?Document=${cpf}`).catch(function (error) {
        if (error.response) {
            return error.response;
        }
    });

    return response.data
}