import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const Container = styled.div`

    background-color: ${COLORS.securityNeutral600};
    justify-content: center;
    display: flex;
    align-items: center;

    .react-html5-camera-photo {
        display: flex;
        max-height: 90vh;
    }
`;

export const LoadingContainer = styled.div`
    height: 100vh;
    justify-content: center;
    display: flex;
    align-items: center;
`;

export const LoadingLabel = styled.label`
    color: ${COLORS.kindness100};
`;