import React, { useCallback, useState, useEffect } from 'react';
import { object, string, ValidationError, number } from 'yup';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/Input/Input';
import InputSelect from '../../components/InputSelect/InputSelect';
import Footer from '../../components/Footer/Footer';
import Button from '../../components/Button/Button';

import * as S from './styles';
import { useOnboarding } from '../../hooks/onboarding';
import { authUnico } from '../../services/authUnico';
import { cpfTicketValidation } from '../../services/cpfTicketValidation';
import { cpfAccountValidation } from '../../services/cpfAccountValidation';


function cpfMask(v) {
    v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
}

export function phoneMask(value) {
    if (!value) return ""
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{2})(\d)/, "($1) $2")
    value = value.replace(/(\d)(\d{4})$/, "$1-$2")
    return value
}

export function maskDate(value) {
    let v = value.replace(/\D/g, '').slice(0, 10);
    if (v.length >= 5) {
        return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
    }
    else if (v.length >= 3) {
        return `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    return v
}

function getValidationErrors(err) {
    const validationErrors = {};

    err.inner.forEach(error => {
        validationErrors[error.path] = error.message;
    });

    return validationErrors;
}

function FormataStringData(data) {
    var dia = data.split("/")[0];
    var mes = data.split("/")[1];
    var ano = data.split("/")[2];

    return ano + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
}

const Onboarding = () => {
    const [name, setName] = useState("");
    const [document, setDocument] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [birthdate, setBirthdate] = useState("");
    const [gender, setGender] = useState();
    const [errors, setErrors] = useState();
    const [showInvalidCPF, setShowInvalidCPF] = useState(false);
    const navigate = useNavigate();
    const { setData, started } = useOnboarding();
    let userSchema = object().shape({
        name: string().min(10, 'Insira um nome válido').required("Nome obrigatório"),
        document: string().min(11, 'Insira um CPF válido').required("CPF obrigatório"),
        email: string().email('Insira um e-mail válido').required('Email obrigatório'),
        phoneNumber: string().required('Celular obrigatório'),
        birthdate: string().required('Data de nascimento obrigatória'),
        gender: number().required('Gênero obrigatório'),
    });

    const handleSubmit = useCallback(async () => {
        try {
            const tokenUnico = await authUnico();
            const formData = {
                name: name,
                email: email,
                document: document.replace(/[^0-9]/g, ""),
                phoneNumber: phoneNumber,
                birthdate: birthdate,
                gender: gender && gender.value
            };

            await userSchema.validate(formData, { abortEarly: false });
            formData.birthdate = new Date(FormataStringData(formData.birthdate)).toISOString()
            formData.phoneNumber = "55" + phoneNumber.replace(/[^0-9]/g, "")
            const { data } = await cpfTicketValidation(document.replace(/[^0-9]/g, ""))
            if (!data) {
                setErrors({
                    document: 'CPF inválido, nenhum convite disponível'
                })
                setShowInvalidCPF(true);
                return;
            }

            const responseAccount = await cpfAccountValidation(document.replace(/[^0-9]/g, ""))
            if (responseAccount.data) {
                setErrors({
                    document: 'Você já possui cadastro, retorne ao menu principal para confirmar sua presença.'
                })
                setShowInvalidCPF(true);
                return;
            }

            setShowInvalidCPF(false);
            setData({ ...formData, JWTUnico: tokenUnico });
            navigate('/facecheck');


        } catch (err) {
            if (err instanceof ValidationError) {
                setErrors(getValidationErrors(err));
                return;
            }
        }

    }, [document, email, name, navigate, setData, userSchema, phoneNumber, birthdate, gender]);

    const onChangeCPF = useCallback((value) => {
        const mask = cpfMask(value);
        setDocument(mask)
    }, []);

    const onChangePhoneNumber = useCallback((value) => {
        const mask = phoneMask(value);
        setPhoneNumber(mask)
    }, []);

    const onChangeBirthdate = useCallback((value) => {
        const mask = maskDate(value);
        setBirthdate(mask)
    }, []);

    useEffect(() => {
        if (!started) {
            navigate("/")
        }
    }, [navigate, started])

    return (
        <S.Container>
            <S.TitleContainer>
                <S.Title>Por favor, insira seus dados:</S.Title>
            </S.TitleContainer>

            <S.FormContainer>
                <Input placeholder="Nome" type="text" name="name" onChange={(e) => setName(e.target.value)} errorMessage={errors?.name} value={name} />
                <Input placeholder="CPF" type="text" name="document" onChange={(e) => onChangeCPF(e.target.value)} errorMessage={errors?.document} value={document} maxLength={14} />
                <Input placeholder="E-mail" type="email" name="email" onChange={(e) => setEmail(e.target.value)} errorMessage={errors?.email} value={email} />
                <Input placeholder="Celular" type="phone" name="phoneNumber" maxLength={15} onChange={(e) => onChangePhoneNumber(e.target.value)} errorMessage={errors?.phoneNumber} value={phoneNumber} />
                <Input placeholder="Data de nascimento" type="text" name="birthdate" maxLength={10} onChange={(e) => onChangeBirthdate(e.target.value)} errorMessage={errors?.birthdate} value={birthdate} />
                <InputSelect
                    onChange={(e) => setGender(e)}
                    value={gender}
                    errorMessage={errors?.gender}
                    placeholder={'Selecione o gênero'}
                    options={[
                        { value: 0, label: 'Homem' },
                        { value: 1, label: 'Mulher' },
                        { value: 2, label: 'Não binário' },
                        { value: 3, label: 'Prefiro não dizer' },
                    ]}
                />
            </S.FormContainer>
            {showInvalidCPF && <S.ContainerAlertCPF>
                <S.Alert>***Lembre-se: Para conseguir realizar o cadastro é necessário utilizar o mesmo CPF que você enviou para um dos organizadores previamente.</S.Alert>
                <S.Alert>Qualquer problema, contate um dos organizadores e tente novamente mais tarde.</S.Alert>
            </S.ContainerAlertCPF>}

            <Footer>
                <Button label={"Continuar"} onClick={() => handleSubmit()} disabled={false} kind={'primary'} />
            </Footer>
        </S.Container>
    );
}

export default Onboarding;