import jsrsasign from 'jsrsasign'
// const basePath = 'https://identityhomolog.acesso.io'

// function requestAnAccessToken(serviceToken, callback) {
//     // Prepare the request
//     let options = {
//         method: 'POST',
//         url: `${basePath}/oauth2/token`,
//         headers: { 'content-type': 'application/x-www-form-urlencoded' },
//         form: {
//             grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
//             assertion: serviceToken
//         }
//     }
//     console.log('Requesting Access Token with self created token:')
//     console.log('', serviceToken)

//     // Ask identity and authorization server for an access token
//     request(options, (error, response, body) => {
//         if (error) {
//             callback(new Error(error))
//         }

//         body = JSON.parse(body)

//         if (body.error) {
//             callback(new Error(`${body.error}: ${body.error_description}`))
//         }

//         callback(null, body)
//     })
// }
    // let tenant_homolog = "9727cd7e-0903-4496-acf9-abdc77f037ab"
    // let privateKey_homolog = `-----BEGIN PRIVATE KEY-----
    // MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC2opcTBVF9un8b
    // kCTydMWAqchfk1vdNsg5hG3Mj24/KnqKqovx2sL09ER2heWEQfaBEgUPCKYBGeDN
    // 0e22n9l6+gMx1MjLaYkkB5e+mV2cdfzVBkWx815vyiS+2vtrkWQfv2l5Mv/dhxwB
    // VL4sRrQmwO0x1HrNPsXocxisfc4GZt9inhID5g/TNPY2WLA9YfL4JyXlMweBb9/F
    // DfWqfVkqKQhwrrylDiBW7ZPhQFXFwwkdgj56K3CTLKlPW0K7FbaUoiR54eCSJDLy
    // KfxjvGL5cSLyLF4VZHTElpXAZ4aRah12cMi5BB928Whoo1e4B5iw0lsZVP2BeZj+
    // Ds7elIDPAgMBAAECggEABJXpgCdCiAseNOV2IAZRjSz2JHwxMsk01sjd7uRtCrX4
    // 1LF6iQyKl5bofydWf28ejtgSe3GmpWFudQx/0PWLgtxzJxWmIwFX2ak9JKJ6KLsH
    // qZuaNWEcbmcfbBFYfbdtUaS/M7VVmkzRpbDog3kU/eIbMoSYvr2EP5VgObiA60Uw
    // Gs3AU8J0srg1HizJJcqpA3lTh0qjkJiEsL9mEV8OaD5ujKLlZqgUYz0/jwhrFONZ
    // ThbdSwS+To3rI6c99QQHwBpfk++CTiMlh0uUXncRGMsGm2ghLfhXKw9pszT+SMEQ
    // vRhIWoUAH4SFG9mSVd8f9ekgqAG6ieXcTKJORQTRcQKBgQDejcorRfYaywfeqHIE
    // LUKs45ZBNvlkyy0PXHnoQMdqxGyLijmPRk2oD6a/uZcSq+EmUUxydhwlBM6osNkH
    // 3itJAu9G/jcE54m3UzkBOmQ6+Rg805b642MvTyH+8PBRAIMdH+h5inuDLLQ+HyuZ
    // 5iaYk711ZwCPqgLlPtyty91ssQKBgQDSFQZxb6N/i38u/Ju2UaZEHWGFI4DVeI/v
    // Z42f7GACk1hUSMpFeecfoN4AhzA00Xj/buqEPF9dtlCrqeJbzg9p5HIeE6bGkCm+
    // D4+4fYVXZV/qVJv8bAgYW3Yuoj2lxoWIwMmU1S2Tky6FeYM5qsH9OtxVxKC7YGLV
    // 5YdmVOYlfwKBgQCenQddV/tSlSkCs6Ku/2LpvTo1OGz+PR/cNthbgu+dVGjX+qeT
    // SVPHV4BMXIUjNYS9vmtxmGPurkJrT78eMGVBH+pQ+pVT1YD9Oz8BdRBCR43SrCqK
    // nMzvxsI/5JAh6u+HLlqNZPkqQA4lyClXAgS38XOx3Ls1qMFviSHWa9lfwQKBgGn0
    // zMEdy1qi4syx/18fIzqrjun1lB8WpZHbqqSENZBW/G/WHp6X8ygSnKWriXa6R8qd
    // CI8VXAwJzfomvtIKxd9kpuHgi3cOct83PgeQ11XS/WNUtS/E1Phj+OFgVrNrwyHM
    // Cl+UfV43F3meZb8KDrlHnKVtfJvE61RyZa4JF+LFAoGAVLkaoiy9EHqe3/y05vx8
    // nRgsOu3iqyBdMHyomNm6xT+liJt/MQKQ1j9QUMrOhadwOHls262I6ODuvN1DmTR9
    // RnmrbYVaspYPePZQJXG8OQwVsB7X/TYNCkQ3cVWzvYQ1xxLZr2wa+8FCwpXmfPx4
    // m0cIIdtd8O05tZT0P8JoiP4=
    // -----END PRIVATE KEY-----
    // `
async function createServiceAccountToken() {
    // Reads the service account private key

    let serviceAccount = 'ugo_idunico'
    const basePath_prod = 'https://identity.acesso.io'
    let tenant_prod = "8468ff48-f318-4469-ad21-d90b6bba43a1"
    let privateKey_prod = `-----BEGIN PRIVATE KEY-----
    MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCWEJckpTLRfnG0
    ERHVB2C9c8k6dzzTX/jFQ99KqrYgzGKhdk5Geir6petSbr39kVXYBA/DgcOGhhbA
    aE+K1yBXXaHbu7ScSXGJXlUg6cWQdaf4wh9cB2QKS7XGkEw0LD4geQ2QdehChp78
    ycey0KZ8ydMpi33kPaDj6dRw2n8FM032l2EncjOjtFilYAGo8HL6cH6tHTg/lJ11
    6HuxduQhpKxps6bK7Y756aHf105gGDeG9xNtlLOo3gEVF2zVy1+RrU5nhfbVSXTQ
    MsiEPZILrs5Fy+TQKMS/NxYn063dfSMUHjx6zSRckJK4zzzSA7V8uZK6IKMO9Sup
    8cAkLb6jAgMBAAECggEAEIF9pm23Q7y1M/mmrPhVRzFbol8oJRXKzqPTBSr2wm/I
    r3rMTEs2vAAQ/K2qnx/jwdFcjIQS5hKMoRg4G4zjBUkKuxkP2hrn7pSeJ3MfJG8U
    jTOI8H3tnoZKVa9cemadZMP73nZhmCOnjCWDniL+FP68B7dMnxS6RCddkxYtX3xk
    aZ4SQWq7ajHA2sdvvVTP5dDt6Al3Vb/wdfArKt+hXQt5Wuop3OlfwzQSzKw32IkS
    W8oWoKlDg1h0NFgW8xKAzV9/SSjr57pL8SEtMtObHaHYJsxzrpmmz5+HvIvQRPUZ
    OXsW9ymfa7x//t6MZl1H7cGnGFde+mDZIWhMCE59/QKBgQDTrdDszms9jhklNH53
    v6NPacGT1YW8Afb+rcjnTH0IL6alu5L0Auw+gdNZqRflDF+RxUZRe/jTXb+LMJb3
    T951hwjK4ayProKbKlIYLGwaS1mbrk9i25WEtj2JIRomSzj23h43Twtvcnf6a3fx
    MitgKp/+xdOWZBt8t4JSyc6cdQKBgQC1fDMT0ndr0I/RLG+XsukNqp1G5QnF0Tfm
    1956u/3YlUEUUkVkT60xsZtwueYe4aeIn2LchFHOaI+a7DcvHJNxLOKIXWjboYEz
    sgTmrcSmP3kXge0Ig8EZ9GZ55kN0DAeLYnhA5VkG/c8XW1TxI4XuZp+329neEIy+
    J9CYNttrtwKBgF4jnRjTrEnR2kj7kYr8BUsaA2cVvMYiMZCOBR0onSp41qo6Q16V
    ti8OWco0M1ZALHiNvXobokuOE9N5JoqSUKMADN79glrcB2WuapgpmWIQRKfB27hh
    Y5uD2NYVjKWfKpRPsCx00Ocdh1ItXUg9QaEhE8qgfDsUBk1u51yeN27JAoGAEyS7
    uo6L3EqOsRDP4J+Vp8RkfLLyOfScwfPpWubzmuH7kGoxOaiR61kBvX1Ogdlps6ak
    ZgBrJKuw4jWiiIyqHNkQODQqOGir2Ukg7ThNMwAlBM7Gsww61UMLgDZCzT3rmc9U
    QfwvTOZ+7sbKifkmSoR2uo0tSRhT0Ecg4L2AnbcCgYEAlEhGIohFg+azX6tz8DGc
    yk0fum7jzrYo6cxhp+J+/ACfLD60eVoEeywZ/hs0PeQeiFE1p0srCFXbNAW1dALj
    gPt8l/oOrVVK66W5yDb0Hoqxr+oxvIqTSea1RN2fsouhoALrNADnRPZAGpWrUAIn
    bejqJHoHbO0UpdmjFwpJmx0=
    -----END PRIVATE KEY-----`


    // Prepare the request
    let payload = {
        iss: `${serviceAccount}@${tenant_prod}.iam.acesso.io`,
        aud: basePath_prod,
        scope: '*',
        exp: Math.floor(Date.now() / 1000) + 3600,
        iat: Math.floor(Date.now() / 1000)
    }

    // Create JWS
    const header = { alg: 'RS256', typ: 'JWT' }
    var sHeader = JSON.stringify(header)
    var sPayload = JSON.stringify(payload)
    return await jsrsasign.jws.JWS.sign(header.alg, sHeader, sPayload, privateKey_prod);;
}

export const authUnico = async (cpf) => {
    return await createServiceAccountToken();
}