import React from 'react';
import * as S from './styles';

function SuccessConfirmed() {

    return (
        <S.Container>
            {/* <S.MessageContainer><p>Cadastro realizado com sucesso.</p></S.MessageContainer> */}
        </S.Container>
    );
}

export default SuccessConfirmed;