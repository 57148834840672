import React from 'react';

import * as S from './styles';

function Button({ label, onClick, disabled, kind }) {
    return (
        <S.Button onClick={onClick} disabled={disabled} kind={kind}>
            {label}
        </S.Button>
    );
}

export default Button;