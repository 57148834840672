import React, { useEffect, useState } from "react";
import {
    UnicoCheckBuilder,
    UnicoThemeBuilder,
    SelfieCameraTypes

} from "unico-webframe"
import { useNavigate } from 'react-router-dom';
// import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import "./styles.css";
import { useOnboarding } from "../../hooks/onboarding";
// import { authUnico } from '../../services/authUnico';
import * as S from './styles'

function FaceCheck() {
    const navigate = useNavigate();
    const [preparedCamera, setPreparedCamera] = useState({});
    const [showBoxCamera, setShowBoxCamera] = useState(false);
    const [loading, setLoading] = useState(true);
    const { setFace } = useOnboarding();
    // const [showLoading, setShowLoading] = useState(false);
    function resetComponentStates() {
        setPreparedCamera({});
        setShowBoxCamera(false);
        setLoading(false);
    }

    const urlPathModels = `${window.location.protocol}//${window.location.host}/models`;

    const unicoTheme = new UnicoThemeBuilder()
        .setColorSilhouetteSuccess("#69c62f")
        .setColorSilhouetteError("#D50000")
        .setColorSilhouetteNeutral("#7175FF")
        .setBackgroundColor("#7175FF")
        .setColorText("#FCF6F7")
        .setBackgroundColorComponents("#325ad4")
        .setColorTextComponents("#FCF6F7")
        .setBackgroundColorButtons("#406ee2")
        .setColorTextButtons("#FCF6F7")
        .setBackgroundColorBoxMessage("#7175FF")
        .setColorTextBoxMessage("#FCF6F7")
        .setHtmlPopupLoading(`<div style="position: absolute; top: 45%; right: 50%; transform: translate(50%, -50%); z-index: 10; text-align: center;">Carregandooooo...</div>`)
        .build();
    const unicoCamera = new UnicoCheckBuilder()
        .setTheme(unicoTheme)
        .setModelsPath(urlPathModels)
        .setResourceDirectory("/resources")
        .build();

    const sdkCallbacks = {
        on: {
            success: async function (obj) {
                const { base64, encrypted } = obj
                await setFace(base64, encrypted);
                navigate('/success')
                // const tokenUnico = await authUnico();

                // const data = {
                //     ...obj,
                //     tokenUnico: tokenUnico
                // };
                // console.log(data, 'data')
                // window.ReactNativeWebView.postMessage(JSON.stringify({
                //     completed: true,
                //     onboarding: data
                // }))

                // resetComponentStates();
            },
            error: function (error) {
                window.console.log(error);
                resetComponentStates();
            },
            support: function (error) {
                console.log(error);
                resetComponentStates();
            }
        }
    };

    const prepareSelfieCamera = async () => {

        const { open } = await unicoCamera.prepareSelfieCamera(
            '/services.json',
            SelfieCameraTypes.NORMAL
        );

        setPreparedCamera({
            openCamera: open,
            isCameraReady: true,
            cameraName: 'Facetec Liveness',
            isUnicoCamera: false,
        });
        setLoading(false);
        document.getElementById('prepare').click()
    }

    useEffect(() => {
        if (document) {
            document.getElementById('initial').click()
        }
    }, [])


    useEffect(() => {
        if (window) {
            resetComponentStates();
            // navigate("/")
        }
    }, [navigate])

    // useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //         event.preventDefault();
    //         localStorage.removeItem('initial')
    //     };
    //     window.addEventListener('beforeunload', handleBeforeUnload);
    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []);

    // async function handleTakePhotoAnimationDone(dataUri) {
    //     // Do stuff with the photo...
    //     console.log(dataUri)
    //     // const { base64, encrypted } = obj
    //     setShowLoading(true)
    //     await setFace(dataUri, "teste");
    //     navigate('/success')
    //     setShowLoading(false)
    // }

    // function handleCameraError(error) {
    //     alert('Ocorreu um erro ao obter a selfie, recarregue a página e tente novamente.');
    // }

    return (
        <S.Container>
            {/* {showLoading && <S.LoadingContainer><S.LoadingLabel>Por favor, aguarde...</S.LoadingLabel></S.LoadingContainer>}
            {!showLoading && <Camera
                onTakePhotoAnimationDone={(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
                onCameraError={(error) => { handleCameraError(error); }}
                imageType={IMAGE_TYPES.JPG}
                isMaxResolution={true}
                isFullscreen={true}
                idealFacingMode={FACING_MODES.CAMERA}
                sizeFactor={0.1}
            />} */}

            {!showBoxCamera && <S.LoadingContainer><S.LoadingLabel>Por favor, aguarde...</S.LoadingLabel></S.LoadingContainer>}
            <div
                style={{
                    display: showBoxCamera ? 'inline' : 'none'
                }}
            >
                <div id="box-camera">
                </div>
            </div>

            <button
                type="button"
                id="initial"
                style={{
                    display: 'none',
                }}
                onClick={() => {
                    prepareSelfieCamera()
                }}
            >
                PrepareCamera Facetec
            </button>

            <button
                type="button"
                id="prepare"
                onClick={() => {
                    if (preparedCamera.isUnicoCamera) {
                        setShowBoxCamera(true);
                    }

                    preparedCamera.openCamera(sdkCallbacks);
                }}
                disabled={!preparedCamera.isCameraReady || loading}
                style={{
                    display: 'none',
                    opacity: preparedCamera.isCameraReady && !loading ? 1 : 0.6,
                    cursor: preparedCamera.isCameraReady && !loading ? 'pointer' : 'no-drop',
                }}
            >
                {loading ? (
                    '...'
                ) : (
                    `OpenCamera ${preparedCamera.cameraName || ''}`
                )}
            </button>
        </S.Container>
    );
}

export default FaceCheck;
