import api from './api';

export const cpfAccountValidation = async (cpf) => {
   
    const response = await api.get(`/onboarding/check-document/${cpf}`).catch(function (error) {
        if (error.response) {
            return error.response;
        }
    });

    return response.data
}