import React from 'react';

import * as S from './styles';

function Input({ placeholder, type, name, onChange, errorMessage, value, maxLength }) {
    return (
        <S.InputContainer>
            <S.Input
                type={type}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                // hasError={errorMessage !== undefined}
                value={value}
                maxLength={maxLength}
            />
            {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
        </S.InputContainer>
    );
}

export default Input;