export const COLORS = {
    black: '#000000',
    neutral300: '#7B8AB5',
    neutral400: '#FFFFFF',
    neutral500: '#788796',
    neutralFeedback400: '#EFECEC',
    neutralFeedback600: '#3E3E3E',
    kindness100: '#FCF6F7',
    neutral700: '#B5BEC7',
    securityNeutral200: '#9FABCF',
    securityNeutral300: '#7B8AB5',
    securityNeutral400: '#515E86',
    securityNeutral500: '#3A435D',
    securityNeutral600: '#0E1322',
    securityNeutral100: '#C9CFE3',
    harmony100: '#EBEBFF',
    harmony400: '#7175FF',
    harmony500: '#5157FF',
    gray: '#D9D9D9',
    inovation400: '#AEF450',
    pink: '#E553A3',
}